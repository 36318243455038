import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { GalleryService } from "src/app/services/gallery.service";
import { ImageModalComponent } from "../image-modal/image-modal.component";
import { InspectionItemImageDetailsDto } from "src/app/models/inspection-result";
import { ImageLoadingService } from "src/app/services/image-loading.service";

@Component({
  selector: "app-image-size",
  templateUrl: "./image-size.component.html",
  styleUrls: ["./image-size.component.scss"],
})
export class ImageSizeComponent implements OnChanges {
  @Input() inspectionItemImageDetails!: InspectionItemImageDetailsDto;
  @Input() globalSelectedSize!: number;
  selectedSize: number = 0;
  constructor(
    public dialog: MatDialog,
    private galleryService: GalleryService,
    private imageLoadingService: ImageLoadingService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["globalSelectedSize"]) {
      setTimeout(() => {
        this.imageLoadingService.incrementLoading();
      });
      this.selectedSize = this.globalSelectedSize;
    }
  }

  openImageModal(itemName: string, failureReasonId: number, inspectionItemFailureReasonId: string) {
    const imageData = this.galleryService.getImage(inspectionItemFailureReasonId);

    if (imageData) {
      let dialogRef: MatDialogRef<ImageModalComponent, unknown> | null = this.dialog.open(ImageModalComponent, {
        data: {
          itemName: itemName,
          failureReasonId: failureReasonId,
          imageData: imageData,
        },
      });

      dialogRef.afterClosed().subscribe(() => {
        dialogRef = null;
      });
    }
  }

  onImageLoad() {
    setTimeout(() => {
      this.imageLoadingService.decrementLoading();
    });
  }

  onSizeSelected(size: number): void {
    this.selectedSize = size;
  }

  getRightImage(inspectionItemFailureReasonId: string) {
    switch (this.selectedSize) {
      case 0:
        return this.galleryService.getResizedImage(inspectionItemFailureReasonId, 300);
      case 1:
        return this.galleryService.getResizedImage(inspectionItemFailureReasonId, 500);
      case 2:
        return this.galleryService.getResizedImage(inspectionItemFailureReasonId, 800);
      default:
        return "";
    }
  }
}
