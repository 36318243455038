<span>
  <app-image-size-select [selectedSize]="selectedSize" (sizeSelected)="onSizeSelected($event)"></app-image-size-select>
  <img
    id="image-{{ inspectionItemImageDetails.articleIdentifier }}-{{ inspectionItemImageDetails.failureReasonId }}"
    [src]="getRightImage(inspectionItemImageDetails.inspectionItemFailureReasonId)"
    class="mr-6 image-container"
    (load)="onImageLoad()"
    (click)="
      openImageModal(
        inspectionItemImageDetails.inspectionItemName,
        inspectionItemImageDetails.failureReasonId,
        inspectionItemImageDetails.inspectionItemFailureReasonId
      )
    "
  />
</span>
