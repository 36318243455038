import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { EventBusActions } from "src/app/models/event-bus-actions";
import { AuthService } from "src/app/services/auth.service";
import { EventBusService } from "src/app/services/event-bus.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  branchId!: string;
  eventBusSubscription: Subscription = new Subscription();
  isSelectBranchPage!: boolean;
  isLoginCallbackPage!: boolean;
  isDashboardPage!: boolean;

  constructor(private authService: AuthService, private router: Router, private eventBusService: EventBusService) {
    router.events.subscribe(() => {
      this.isSelectBranchPage = window.location.pathname === "/select-branch";
      this.isLoginCallbackPage = window.location.pathname === "/login-callback";
      this.isDashboardPage = window.location.pathname === `/branch/${this.branchId}/dashboard`;
    });
  }

  ngOnInit(): void {
    this.eventBusSubscription.add(
      this.eventBusService.on(EventBusActions.branchIdReceived, (branchId: string) => {
        this.branchId = branchId;
      })
    );
  }

  get isVisible() {
    return !(this.isSelectBranchPage || this.isLoginCallbackPage);
  }

  public get username() {
    return this.authService.getUserName();
  }

  public logout() {
    this.authService.logoutSession();
  }

  navigateToBranches(event: Event) {
    if (!this.isSelectBranchPage) {
      this.router.navigateByUrl("/select-branch");
    } else {
      event.preventDefault();
    }
  }

  navigateToDashboard(event: Event) {
    if (!this.isDashboardPage) {
      this.router.navigate(["branch", this.branchId, "dashboard"]);
    } else {
      event.preventDefault();
    }
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe();
  }
}
