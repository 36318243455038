import jsPDF from "jspdf";
import { TranslationService } from "./translation.service";
import { InspectionResultDetailsDto } from "../models/inspection-result";
import { ExportPdfBaseService } from "./export-pdf-base.service";
import i18next from "i18next";

export class ExportTestDetailsService extends ExportPdfBaseService {
  constructor(
    protected override translationService: TranslationService,
    protected override inspectionResultDetails: InspectionResultDetailsDto
  ) {
    super(inspectionResultDetails, translationService);

    this.jsPdf = new jsPDF({
      unit: "px",
      orientation: "landscape",
      format: "A4",
    });
  }

  downloadPdf(callback: () => void): void {
    this.addInspectionResultTitle();
    this.addInspectionDetails();

    var testDetails = document.querySelector(".test-details-pdf-preview .test-details-tab") as HTMLElement;
    const branchId = this.branchName.substring(0, this.branchName.indexOf(" "));
    const fileName = `YQC-${this.translationService.translate(
      `General.Result`
    )}_${branchId}_${this.translationService.translate(`General.Details`)}_${this.inspectionDate}_${this.auditorName}_${
      i18next.language
    }.pdf`;
    const htmlPositionY = this.currentPositionY - this.currentIncrement;

    this.jsPdf.html(testDetails, {
      callback: (doc: jsPDF) => {
        this.addFootersToAllPages(fileName);
        doc.save(fileName);
        callback();
      },
      x: 10,
      y: htmlPositionY,
      html2canvas: {
        scale: 0.43,
      },
      margin: [10, 0, 40, 0],
      autoPaging: "text",
    });
  }
}
