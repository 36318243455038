<div class="filters-container">
  <loading *ngIf="isLoading$ | async"></loading>
  <mat-form-field class="filter-selector">
    <mat-label>{{ "InspectionResult.GroupGallery" | i18next }}</mat-label>
    <mat-select (selectionChange)="onSelectionChange($event.value)">
      <mat-option [value]="galleryGroupType.GroupByInspectionGroup">
        {{ "InspectionResult.GalleryGroupType.GroupByInspectionGroup" | i18next }}</mat-option
      >
      <mat-option [value]="galleryGroupType.GroupByFailureReason">
        {{ "InspectionResult.GalleryGroupType.GroupByFailureReason" | i18next }}
      </mat-option>
      <mat-option [value]="galleryGroupType.GroupByArticle">
        {{ "InspectionResult.GalleryGroupType.GroupByArticle" | i18next }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <app-image-size-select
    [imageSizeClass]="'filter-selector'"
    [selectedSize]="globalSelectedSize"
    (sizeSelected)="onSizeSelected($event)"
  ></app-image-size-select>
</div>

<div *ngIf="gallerySelectedGroup == galleryGroupType.GroupByInspectionGroup">
  <app-inspection-groups-group-gallery
    [inspectionResultDetails]="inspectionResultDetails"
    [globalSelectedSize]="globalSelectedSize"
  ></app-inspection-groups-group-gallery>
</div>

<div *ngIf="gallerySelectedGroup == galleryGroupType.GroupByFailureReason">
  <app-failure-reasons-group-gallery
    [inspectionResultDetails]="inspectionResultDetails"
    [globalSelectedSize]="globalSelectedSize"
  ></app-failure-reasons-group-gallery>
</div>

<div *ngIf="gallerySelectedGroup == galleryGroupType.GroupByArticle">
  <app-inspection-items-group-gallery
    [inspectionResultDetails]="inspectionResultDetails"
    [globalSelectedSize]="globalSelectedSize"
  ></app-inspection-items-group-gallery>
</div>
