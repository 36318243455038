import { Component, Input, EventEmitter, Output, OnChanges, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-image-size-select",
  templateUrl: "./image-size-select.component.html",
})
export class ImageSizeSelectComponent {
  @Input() imageSizeClass!: string;
  @Input() selectedSize = 0;
  sizeOptions = [0, 1, 2];

  @Output() sizeSelected = new EventEmitter<number>();

  onSizeChange(): void {
    this.sizeSelected.emit(this.selectedSize);
  }
}
