<section class="test-items-wrap">
  <div class="test-sheet-list">
    <div>
      {{
        "ConfigurationList.InspectionGroup." + piecesData.inspectionGroup.inspectionGroupIdentifier.toString() | i18next
      }}
    </div>
    <div class="mb-2">
      <div *ngFor="let inspectionItem of piecesData.inspectionGroup?.inspectionItems" class="articles-list">
        <div>{{ inspectionItem.articleIdentifier }} {{ inspectionItem.name }}</div>
        <div class="text-center">
          <input readonly value="{{ inspectionItem.quantity || 0 }}" class="small" />
        </div>
        <div class="text-center delete-container">
          <doka-button
            color="clear"
            icon="delete"
            [iconOnly]="true"
            class="button-icon"
            (click)="deleteItem(inspectionItem.inspectionItemId, inspectionItem.name)"
          ></doka-button>
        </div>
      </div>
      <section class="focus-items">
        <div *ngFor="let focusItem of piecesData.inspectionGroup?.focusInspectionItems" class="articles-list">
          <div>{{ focusItem.articleIdentifier }} {{ focusItem.name }}</div>
          <div class="text-center">
            <input readonly value="{{ focusItem.quantity || 0 }}" class="small" />
          </div>
          <div class="text-center">
            <doka-button
              color="clear"
              icon="delete"
              [iconOnly]="true"
              class="button-icon"
              (click)="deleteFocusItem(focusItem.inspectionItemId, focusItem.name)"
            ></doka-button>
          </div>
        </div>
        <form
          class="articles-list mt-2"
          [formGroup]="inspectionGroupForm"
          #formDirective="ngForm"
          (ngSubmit)="addFocusItem(formDirective)"
        >
          <mat-form-field class="branch-select search-article" appearance="fill">
            <input
              class="search-input"
              type="text"
              matInput
              [matAutocomplete]="auto"
              formControlName="article"
              placeholder="{{ 'TestSheet.SearchArticles' | i18next }}"
            />
            <mat-autocomplete
              #auto="matAutocomplete"
              [displayWith]="displayFn"
              (optionSelected)="onSelectionChange(formDirective)"
            >
              <mat-option
                *ngFor="let inspectionGroup of piecesData.inspectionGroup?.focusItemsOptions"
                [value]="inspectionGroup"
                [ngClass]="allowSelection(inspectionGroup.name)"
              >
                {{ inspectionGroup.articleIdentifier }} {{ inspectionGroup.name || "Branch.NoMatch" | i18next }}
              </mat-option>
              <div class="ml-4 my-2" *ngIf="piecesData.inspectionGroup?.focusItemsOptions?.[0]?.name">
                <button
                  mat-raised-button
                  color="primary"
                  type="submit"
                  (click)="loadNextFocusItems()"
                  [disabled]="piecesData.areTotalPiecesLoaded"
                >
                  {{ "TestSheet.LoadNext" | i18next }}
                </button>
              </div>
            </mat-autocomplete>
            <mat-error
              *ngIf="inspectionGroupForm.controls['article']?.errors?.['invalidArticleQuantity'] && !inspectionGroupForm.controls['article'].errors?.['required'] && !inspectionGroupForm.controls['article'].errors?.['invalidArticle']"
              class="mt-4"
            >
              {{ "TestSheet.AvailableQuantity" | i18next : { totalQuantity: totalStockQuantity } }}
            </mat-error>
            <mat-error
              *ngIf="inspectionGroupForm.controls['article'].hasError('required') && formDirective.submitted"
              class="mt-4"
              >{{ "General.InputRequired" | i18next }}
            </mat-error>
            <mat-error
              *ngIf="!inspectionGroupForm.controls['article'].hasError('required') && inspectionGroupForm.controls['article'].errors?.['invalidArticle']"
              class="mt-4"
            >
              {{ "TestSheet.InvalidArticle" | i18next }}
            </mat-error>
          </mat-form-field>
          <div class="text-center self-center">
            <mat-form-field class="focus-items-quantity" appearance="fill">
              <input matInput type="number" formControlName="quantity" placeholder="0" />
              <mat-error
                *ngIf="inspectionGroupForm.controls['quantity'].hasError('required') && formDirective.submitted"
                class="mt-6"
                >{{ "General.InputRequired" | i18next }}
              </mat-error>
              <mat-error *ngIf="inspectionGroupForm.controls['quantity']?.errors?.['min']" class="mt-6">
                {{ "General.Min" | i18next }} 1
              </mat-error>
              <mat-error
                *ngIf="inspectionGroupForm.controls['quantity']?.errors?.['max'] && !inspectionGroupForm.controls['article']?.errors?.['invalidArticleQuantity']"
                class="mt-6"
              >
                {{ "General.Max" | i18next }}
                {{inspectionGroupForm.controls['quantity'].errors?.['max']?.['max']}}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="text-center">
            <button mat-raised-button color="primary" type="submit" [disabled]="piecesData.availablePieces === 0">
              {{ "TestSheet.AddFocusItem" | i18next }}
            </button>
          </div>
        </form>
      </section>
    </div>
  </div>
</section>
