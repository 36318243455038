<main class="confirm-dialog-wrapper">
  <div mat-dialog-title class="title">{{ data.title | i18next }}</div>
  <div mat-dialog-content>
    <p class="text-m">{{ data.question | i18next }}{{ data.name }}</p>
  </div>
  <div mat-dialog-actions class="justify-right actions-container">
    <doka-button color="clear" class="main-button" (click)="onClose()">
      {{ "General.Cancel" | i18next }}</doka-button
    >
    <doka-button color="danger" class="main-button" *ngIf="confirmButtonColor === 'danger'" (click)="onConfirm()">
      {{ data.confirmButtonText | i18next }}</doka-button
    >
    <button mat-raised-button *ngIf="confirmButtonColor === 'accent'" [mat-dialog-close]="true" color="accent">
      {{ data.confirmButtonText | i18next }}
    </button>
  </div>
</main>
