import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { InspectionResultDetailsDto, InspectionResultGallery } from "src/app/models/inspection-result";
import { GalleryGroupTypeEnum } from "src/app/enums/gallery-group-type.enum";
import { ImageLoadingService } from "src/app/services/image-loading.service";

@Component({
  selector: "app-gallery-overview",
  templateUrl: "./gallery-overview.component.html",
  styleUrls: ["./gallery-overview.component.scss"],
})
export class GalleryOverviewComponent implements OnInit {
  @Input() inspectionResultDetails!: InspectionResultDetailsDto;
  isLoading$ = this.imageLoadingService.loading$;
  gallerySelectedGroup!: GalleryGroupTypeEnum;
  failureReasonsList: InspectionResultGallery[] = [];
  galleryGroupType = GalleryGroupTypeEnum;
  globalSelectedSize = 0;

  constructor(private imageLoadingService: ImageLoadingService) {}

  ngOnInit(): void {
    this.gallerySelectedGroup = this.galleryGroupType.GroupByInspectionGroup;
  }
  onSizeSelected(size: number): void {
    this.globalSelectedSize = size;
  }

  onSelectionChange(galleryOverviewGroup: GalleryGroupTypeEnum) {
    this.gallerySelectedGroup = galleryOverviewGroup;
  }
}
