import { Component, Input, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { EventBusActions } from "src/app/models/event-bus-actions";
import { ExportGalleryOverviewDto } from "src/app/models/export-pdf.model";
import {
  InspectionItemsGalleryDto,
  InspectionResultDetailsDto,
} from "src/app/models/inspection-result";
import { EventBusService, EmitEvent } from "src/app/services/event-bus.service";
import { ExportPdfService } from "src/app/services/export-pdf.service";
import { GalleryService } from "src/app/services/gallery.service";

@Component({
  selector: "app-inspection-items-group-gallery",
  templateUrl: "./inspection-items-group-gallery.component.html",
})
export class InspectionItemsGroupGalleryComponent implements OnInit {
  @Input() inspectionResultDetails!: InspectionResultDetailsDto;
  @Input() globalSelectedSize!: number;

  isLoading = false;
  inspectionItemsGallery: InspectionItemsGalleryDto[] = [];
  eventBusSubscription: Subscription = new Subscription();

  constructor(
    private galleryService: GalleryService,
    private exportPdfService: ExportPdfService,
    protected eventBusService: EventBusService
  ) {}

  ngOnInit(): void {
    this.getGallery();
    this.eventBusSubscription.add(
      this.eventBusService.on(EventBusActions.downloadPdf, (isLoading: boolean) => {
        this.isLoading = isLoading;
      })
    );
  }

  get hasDataAvailable() {
    return this.inspectionItemsGallery.length > 0;
  }

  getGallery() {
    this.isLoading = true;
    this.galleryService.getInspectionGalleryGroupedByItem(this.inspectionResultDetails.inspectionId).subscribe({
      next: (data) => {
        this.inspectionItemsGallery = data;
        this.isLoading = false;
      },
      error: () => (this.isLoading = false),
    });
  }

  downloadAsPdf() {
    this.eventBusService.emit(new EmitEvent(EventBusActions.downloadPdf, true));
    const exportGalleryOverview: ExportGalleryOverviewDto = {
      failureReasonsList: [
        {
          inspectionGroupIdentifier: Number(this.inspectionItemsGallery[0].inspectionGroupIdentifier),
          inspectionItems: this.inspectionItemsGallery,
        },
      ],
      inspectionResultDetails: this.inspectionResultDetails,
    };

    setTimeout(() => {
      this.exportPdfService.galleryOverviewGeneratePdf(exportGalleryOverview);
    }, 500);
  }
}
