<div class="page-container select">
  <div class="select-branch-image"></div>
  <div class="select-branch-container">
    <section class="select-branch-wrapper">
      <div [ngClass]="{ 'no-access': !hasAccess }" class="select-branch-form">
        <img src="./../../../assets/images/Icon_Yard_QC.png" />
        <select-branch *ngIf="hasAccess"></select-branch>
        <div *ngIf="!hasAccess" class="label">
          Access Denied. You do not have enough permissions to access the application.
        </div>
      </div>
    </section>
  </div>
</div>
