import jsPDF, { ImageProperties } from "jspdf";
import { InspectionItemType, InspectionResultGallery } from "../models/inspection-result";
import { TranslationService } from "./translation.service";
import { ExportGalleryOverviewDto } from "../models/export-pdf.model";
import { ExportPdfBaseService } from "./export-pdf-base.service";
import { EmitEvent, EventBusService } from "./event-bus.service";
import { EventBusActions } from "../models/event-bus-actions";

export class ExportGalleryOverviewService extends ExportPdfBaseService {
  private readonly failureReasonImageIncrement = 60;
  private readonly numberToDivideDimensions = 2;
  private readonly lineHeightMultiplier = 1.2;
  private availableSpaceY: number;
  private inspectionItemGroupFontSize: number;
  private failureReasonFontSize: number;
  private totalBlockHeight = 0;
  private footerHeight = 20;
  private blockSpacing: number = 10;
  private imageProperties!: ImageProperties;

  constructor(
    protected override translationService: TranslationService,
    protected eventBusService: EventBusService,
    private exportGalleryOverview: ExportGalleryOverviewDto
  ) {
    super(exportGalleryOverview.inspectionResultDetails, translationService);

    this.jsPdf = new jsPDF({
      unit: "px",
      orientation: "portrait",
      format: "A4",
    });

    this.availableSpaceY = this.jsPdf.getCurrentPageInfo().pageContext.mediaBox.topRightY;
    this.inspectionItemGroupFontSize = 16;
    this.failureReasonFontSize = 12;
  }

  downloadPdf() {
    this.addInspectionResultTitle();
    this.addInspectionDetails();

    this.exportGalleryOverview.failureReasonsList.forEach((resultGallery) => {
      this.adjustPositionsForGroupHeader(resultGallery);

      resultGallery.inspectionItems.forEach((item, index) => {
        if (index === 0) {
          const groupNameHeight = this.inspectionItemGroupFontSize * this.lineHeightMultiplier;
          if (this.currentPositionY + groupNameHeight > this.availableSpaceY) {
            this.checkAndAddPageIfNeeded(groupNameHeight);
          }
          this.addGroupName(resultGallery);
        }
        const itemNameHeight = this.inspectionItemGroupFontSize * this.lineHeightMultiplier;
        if (this.currentPositionY + itemNameHeight > this.availableSpaceY) {
          this.checkAndAddPageIfNeeded(itemNameHeight);
        }
        this.addItemName(item);

        let cumulativeImageIndex = 0;
        item.inspectionItemFailureReasons.forEach((reason) => {
          reason.inspectionItemFailureReasonIds.forEach((reasonId) => {
            const image = this.getFailureReasonImage(item, reasonId);

            const totalImages = item.inspectionItemFailureReasons.reduce(
              (accumulator, failureReason) => accumulator + failureReason.inspectionItemFailureReasonIds.length,
              0
            );

            if (image) {
              this.imageProperties = this.jsPdf.getImageProperties(image);
              this.addFailureReasonImage(
                reason.failureReasonId,
                this.imageProperties,
                image,
                cumulativeImageIndex,
                totalImages
              );
              cumulativeImageIndex++;
            }
          });
        });
      });
    });
    this.addFootersToAllPages(this.fileName);
    this.savePdf();
  }

  private savePdf(): void {
    this.jsPdf
      .save(this.fileName, { returnPromise: true })
      .then(() => {})
      .finally(() => {
        this.eventBusService.emit(new EmitEvent(EventBusActions.downloadPdf, false));
      });
  }

  private getFailureReasonImage(
    item?: InspectionItemType,
    inspectionItemFailureReasonId?: string
  ): HTMLImageElement | null {
    const imageId = `image-${item?.articleIdentifier}-${inspectionItemFailureReasonId}`;
    const image = document.getElementById(imageId) as HTMLImageElement;

    return image;
  }

  private addItemName(item: InspectionItemType): void {
    const itemName = `${item.articleIdentifier} - ${item.name}`;
    const itemNameHeight = this.inspectionItemGroupFontSize * this.lineHeightMultiplier;

    this.jsPdf.setFontSize(this.inspectionItemGroupFontSize);
    this.jsPdf.text(itemName, this.currentPositionX, this.currentPositionY);

    this.currentPositionY += this.currentIncrement;
    this.totalBlockHeight += itemNameHeight;
  }

  private addGroupName(resultGallery: InspectionResultGallery): void {
    const groupName = this.translationService.translate(
      `ConfigurationList.InspectionGroup.${resultGallery.inspectionGroupIdentifier}`
    );
    const groupNameHeight = this.inspectionItemGroupFontSize * this.lineHeightMultiplier;

    this.jsPdf.setFontSize(this.inspectionItemGroupFontSize);
    this.jsPdf.text(groupName, this.currentPositionX, this.currentPositionY);

    this.currentPositionY += this.currentIncrement;
    this.totalBlockHeight += groupNameHeight;
  }

  private checkAndAddPageIfNeeded(spaceNeeded: number): void {
    const hasSpaceAvailable = this.currentPositionY + spaceNeeded + this.footerHeight <= this.availableSpaceY;

    if (!hasSpaceAvailable) {
      this.adjustCurrentCoordinates();
    }
  }

  private adjustPositionsForGroupHeader(resultGallery: InspectionResultGallery): void {
    const firstInspectionItem = resultGallery.inspectionItems[0];
    const firstInspectionItemFailureReason =
      firstInspectionItem?.inspectionItemFailureReasons[0].inspectionItemFailureReasonIds[0];

    const image = this.getFailureReasonImage(firstInspectionItem, firstInspectionItemFailureReason);
    if (image) {
      const imageProperties = this.jsPdf.getImageProperties(image);
      const necessarySpaceForFailureReasonImage =
        imageProperties.height / this.numberToDivideDimensions +
        this.inspectionItemGroupFontSize +
        this.inspectionItemGroupFontSize +
        this.failureReasonFontSize +
        this.failureReasonImageIncrement;

      this.checkAndAddPageIfNeeded(necessarySpaceForFailureReasonImage);
    }
  }

  private adjustCurrentCoordinates(): void {
    this.jsPdf.addPage();
    this.currentPositionY = 20;
    this.currentIncrement = 20;
    const pageHeight = this.jsPdf.internal.pageSize.height;
    this.availableSpaceY = pageHeight - this.footerHeight;
    this.totalBlockHeight = 0;
  }

  private calculateResizedDimensions(
    imageProperties: ImageProperties,
    columnWidth: number
  ): { width: number; height: number } {
    const maxDimension = columnWidth - this.blockSpacing;
    const aspectRatio = imageProperties.width / imageProperties.height;

    let newHeight = imageProperties.height;
    let newWidth = imageProperties.width;

    if (newHeight > maxDimension) {
      newHeight = maxDimension;
      newWidth = newHeight * aspectRatio;
    }

    if (newWidth > maxDimension) {
      newWidth = maxDimension;
      newHeight = newWidth / aspectRatio;
    }
    return { width: newWidth, height: newHeight };
  }

  private addFailureReasonImage(
    failureReasonId: number,
    imageProperties: ImageProperties,
    image: HTMLImageElement,
    index: number,
    totalImages: number
  ): void {
    const pageWidth = this.jsPdf.internal.pageSize.width;
    const margin = 20;
    const columnWidth = (pageWidth - margin * 2) / 2;
    const resizedDimensions = this.calculateResizedDimensions(imageProperties, columnWidth);
    const imageHeight = resizedDimensions.height;
    const imageWidth = resizedDimensions.width;
    const textHeight = this.failureReasonFontSize * this.lineHeightMultiplier;
    const blockHeight = columnWidth + textHeight;
    const reasonName = this.translationService.translate(`InspectionResult.ItemFailureReasons.List.${failureReasonId}`);

    const isLeftColumn = index % 2 === 0;
    const xPosition = isLeftColumn ? margin : margin + columnWidth + this.blockSpacing;

    if (this.currentPositionY + blockHeight + this.totalBlockHeight > this.availableSpaceY) {
      this.adjustCurrentCoordinates();
    }

    this.jsPdf.setFontSize(this.failureReasonFontSize);
    this.jsPdf.text(reasonName, xPosition, this.currentPositionY);

    const imageY = this.currentPositionY + textHeight;
    this.jsPdf.addImage(image, "JPEG", xPosition, imageY, imageWidth, imageHeight);

    if (!isLeftColumn || index === totalImages - 1) {
      this.currentPositionY += blockHeight + this.blockSpacing;
    }
  }
}
