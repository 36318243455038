import { Component, OnInit } from "@angular/core";
import { FormGroupDirective } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BasePage } from "src/app/pages/base-page";
import { ConfirmationDialogComponent } from "src/app/components/confirmation-dialog/confirmation-dialog.component";
import { FocusItemDto } from "src/app/models/focus-item";
import { InspectionDetails } from "src/app/models/inspection-details";
import { EventBusService } from "src/app/services/event-bus.service";
import { FocusItemsService } from "src/app/services/focus-items.service";
import { InspectionItemsService } from "src/app/services/inspection-items.service";
import { InspectionService } from "src/app/services/inspections.service";
import { ItemsService } from "src/app/services/items.service";
import { ToastService } from "src/app/services/toast.service";

@Component({
  selector: "app-test-sheet",
  templateUrl: "./test-sheet.page.html",
  styleUrls: ["./test-sheet.page.scss"],
})
export class TestSheetPage extends BasePage implements OnInit {
  inspection?: InspectionDetails;
  isLoading = false;
  totalPieces = 0;
  availablePieces = 0;
  focusItems = 0;
  availableFocusItems!: number;
  areTotalPiecesLoaded!: boolean;
  availableStockQuantity!: number | undefined;
  previousFormDirective!: FormGroupDirective;
  isStockQuantityExceeded!: boolean;

  constructor(
    public confirmationDialog: MatDialog,
    private inspectionsService: InspectionService,
    private itemsService: ItemsService,
    private focusItemsService: FocusItemsService,
    private inspectionItemsService: InspectionItemsService,
    private toastService: ToastService,
    private router: Router,
    protected override route: ActivatedRoute,
    protected override eventBusService: EventBusService
  ) {
    super(route, eventBusService);
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.emitBranchId(params);
      this.getInspection(params["id"]);
    });
  }

  confirmDialog(
    title: string,
    question: string,
    name: string,
    action: () => void,
    confirmButtonText: string,
    confirmButtonColor?: string
  ) {
    let dialogRef: MatDialogRef<ConfirmationDialogComponent, unknown> | null = this.confirmationDialog.open(
      ConfirmationDialogComponent,
      {
        data: { title, question, name, confirmButtonText, confirmButtonColor },
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        action();
      }
      dialogRef = null;
    });
  }

  searchValue(data: { searchText: string; inspectionId: string; inspectionGroupId: string; currentPage: number }) {
    this.isLoading = true;
    this.itemsService.getItems(data.inspectionId, data.searchText, data.currentPage).subscribe({
      next: (result) => {
        this.isLoading = false;
        if (this.inspection) {
          this.availableFocusItems = result.totalNumberOfItems;
          const index = this.inspection?.inspectionGroups.findIndex(
            (group) => group.inspectionGroupId === data.inspectionGroupId
          );

          const hasLoadedFocusItems =
            this.inspection.inspectionGroups[index].focusItemsOptions &&
            this.inspection.inspectionGroups[index].focusItemsOptions.length > 0 &&
            this.inspection.inspectionGroups[index].focusItemsOptions[0].name;
          const emptyFocusItems = [{ articleIdentifier: "", inspectionItemId: "", itemId: "", name: "", quantity: 0 }];
          const currentLoadedFocusItems = this.inspection.inspectionGroups[index].focusItemsOptions;
          const hasResults = result && result.items.length > 0;

          this.inspection.inspectionGroups[index].focusItemsOptions =
            data.currentPage === 0
              ? hasResults
                ? result.items
                : emptyFocusItems
              : hasResults
              ? hasLoadedFocusItems
                ? [...currentLoadedFocusItems, ...result.items]
                : result.items
              : hasLoadedFocusItems
              ? [...currentLoadedFocusItems]
              : emptyFocusItems;

          this.areTotalPiecesLoaded =
            this.availableFocusItems === this.inspection.inspectionGroups[index].focusItemsOptions.length;
        }
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  onFocusItemSelectionChange(formDirective: FormGroupDirective) {
    this.previousFormDirective = formDirective;
  }

  addFocusItem(focusItem: FocusItemDto) {
    this.isLoading = true;
    this.focusItemsService
      .saveFocusItem({
        ...focusItem,
        inspectionId: this.inspection?.inspectionId,
      })
      .subscribe({
        next: (data) => {
          const focusItemResponse = JSON.parse(data);
          if (!focusItemResponse.isSuccess) {
            this.isStockQuantityExceeded = this.availableStockQuantity ? true : false;
            this.availableStockQuantity = focusItemResponse.stockQuantity;
            this.isLoading = false;
          } else {
            this.availableStockQuantity = undefined;
            this.getInspection(this.inspection?.inspectionId as string);
          }
        },
        error: () => {},
      });
  }

  deleteFocusItem(item: { inspectionItemId: string; name: string }) {
    this.confirmDialog(
      "Inspection.DeleteItemTitle",
      "Inspection.DeleteItemDescription",
      item.name,
      () => {
        this.isLoading = true;
        this.focusItemsService.deleteFocusItem(item.inspectionItemId).subscribe(() => {
          this.getInspection(this.inspection?.inspectionId as string);
        });
      },
      "General.Delete",
      "danger"
    );
  }

  deleteItem(item: { inspectionItemId: string; name: string }) {
    this.confirmDialog(
      "Inspection.DeleteItemTitle",
      "Inspection.DeleteItemDescription",
      item.name,
      () => {
        this.isLoading = true;
        this.inspectionItemsService.deleteItem(item.inspectionItemId).subscribe(() => {
          this.getInspection(this.inspection?.inspectionId as string);
        });
      },
      "General.Delete",
      "danger"
    );
  }

  resetAvailableStockQuantity() {
    this.availableStockQuantity = undefined;
  }

  goToConfiguration() {
    this.router.navigateByUrl(`branch/${this.branchId}/configuration-list/${this.inspection?.numberOfResults}`);
  }

  resetConfiguration() {
    this.confirmDialog(
      "TestSheet.ResetConfiguration",
      "Inspection.ResetConfigurationDescription",
      "",
      () => {
        this.isLoading = true;
        this.inspectionsService.resetConfiguration(this.inspection?.inspectionId).subscribe(() => {
          this.getInspection(this.inspection?.inspectionId as string);
        });
      },
      "General.Reset",
      "danger"
    );
  }

  export() {
    this.confirmDialog(
      "TestSheet.ExportTitle",
      "TestSheet.ExportDescription",
      "",
      () => {
        this.isLoading = true;
        this.inspectionsService.finishSetup(this.inspection?.inspectionId).subscribe(() => {
          this.router.navigateByUrl(`branch/${this.branchId}/exported-inspection/${this.inspection?.inspectionId}`);
        });
      },
      "TestSheet.Export",
      "accent"
    );
  }

  private getInspection(inspectionId: string) {
    this.isLoading = true;
    this.inspectionsService.getInspection(inspectionId).subscribe({
      next: (data) => {
        if (data.isSetupFinished) {
          this.router.navigateByUrl(`branch/${this.branchId}/dashboard`);
        }
        this.inspection = data;
        this.calculateTotalPieces(data);
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.toastService.openSnackBar("General.UnknownError");
      },
    });
  }

  private calculateTotalPieces(data: InspectionDetails) {
    this.totalPieces = 0;
    this.focusItems = 0;
    data.inspectionGroups.forEach((group) => {
      this.totalPieces += group.inspectionItems.reduce((accumulator, value) => {
        return accumulator + value.quantity;
      }, 0);
      this.focusItems += group.focusInspectionItems.reduce((accumulator, value) => {
        return accumulator + value.quantity;
      }, 0);
    });
    this.totalPieces += this.focusItems;

    if (this.inspection?.numberOfResults) {
      this.availablePieces = this.inspection.numberOfResults - this.totalPieces;
    }
  }
}
