<main class="failure-reason-image-modal">
  <h1 mat-dialog-title>{{ data.itemName }}</h1>
  <h2 mat-dialog-title>
    {{ "InspectionResult.ItemFailureReasons.List." + data.failureReasonId | i18next }}
  </h2>
  <mat-dialog-content class="mat-typography image-container" [ngClass]="{ 'zoom-in': isZoomedIn }">
    <img [src]="data.imageData" />
  </mat-dialog-content>
  <div mat-dialog-actions>
    <doka-button color="clear" class="main-button" (click)="onClose()"> {{ "General.Close" | i18next }}</doka-button>
    <div class="zoom-actions">
      <doka-icon name="zoom_in" color="#004588" size="5xl" (click)="zoomIn()"></doka-icon>
      <doka-icon name="zoom_out" color="#004588" size="5xl" (click)="zoomOut()"></doka-icon>
    </div>
  </div>
</main>
