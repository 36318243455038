<app-download-button-pdf
  [isLoading]="isLoading"
  [hasDataAvailable]="hasDataAvailable"
  [downloadAsPdf]="downloadAsPdf.bind(this)"
></app-download-button-pdf>

<div class="failures-list-container gallery-overview">
  <loading *ngIf="isLoading"></loading>
  <div class="failures-list list-container" *ngFor="let failureReason of inspectionFailureReasonsGallery">
    <h2>
      {{ "InspectionResult.ItemFailureReasons.List." + failureReason.failureReasonId | i18next }}
    </h2>
    <div *ngFor="let inspectionItem of failureReason.inspectionItemDetailsGallery">
      <h3>{{ inspectionItem.articleIdentifier }} - {{ inspectionItem.name }}</h3>
      <h4>
        {{ "ConfigurationList.InspectionGroup." + inspectionItem.inspectionGroupIdentifier | i18next }}
      </h4>
      <div class="failure-image-container">
        <div *ngFor="let inspectionItemFailureReasonId of inspectionItem.inspectionItemFailureReasonIds">
          <app-image-size
            [inspectionItemImageDetails]="{
              inspectionItemFailureReasonId: inspectionItemFailureReasonId,
              failureReasonId: failureReason.failureReasonId,
              inspectionItemName: inspectionItem.name,
              articleIdentifier: inspectionItem.articleIdentifier
            }"
            [globalSelectedSize]="globalSelectedSize"
          ></app-image-size>
        </div>
      </div>
    </div>
  </div>
</div>
