import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";

export interface ImageDialogData {
  itemName: string;
  failureReasonId: number;
  imageData: string;
}

@Component({
  selector: "app-image-modal",
  templateUrl: "./image-modal.component.html",
  styleUrls: ["./image-modal.component.scss"],
})
export class ImageModalComponent {
  isZoomedIn = false;
  constructor(
    public dialogRef: MatDialogRef<ImageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ImageDialogData,
  ) {}


  zoomIn(): void {
    this.isZoomedIn = true;
  }

  zoomOut(): void {
    this.isZoomedIn = false;
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
