import { Component, Input } from "@angular/core";
import { Subscription } from "rxjs";
import { EventBusActions } from "src/app/models/event-bus-actions";
import { ExportGalleryOverviewDto } from "src/app/models/export-pdf.model";
import {
  InspectionFailureReasonsGalleryDto,
  InspectionResultDetailsDto,
  InspectionResultGallery,
} from "src/app/models/inspection-result";
import { EventBusService, EmitEvent } from "src/app/services/event-bus.service";
import { ExportPdfService } from "src/app/services/export-pdf.service";
import { GalleryService } from "src/app/services/gallery.service";

@Component({
  selector: "app-failure-reasons-group-gallery",
  templateUrl: "./failure-reasons-group-gallery.component.html",
})
export class FailureReasonsGroupGalleryComponent {
  @Input() inspectionResultDetails!: InspectionResultDetailsDto;
  @Input() globalSelectedSize!: number;

  isLoading = false;
  inspectionFailureReasonsGallery: InspectionFailureReasonsGalleryDto[] = [];
  eventBusSubscription: Subscription = new Subscription();

  constructor(
    private galleryService: GalleryService,
    private exportPdfService: ExportPdfService,
    protected eventBusService: EventBusService
  ) {}

  ngOnInit(): void {
    this.getGallery();
    this.eventBusSubscription.add(
      this.eventBusService.on(EventBusActions.downloadPdf, (isLoading: boolean) => {
        this.isLoading = isLoading;
      })
    );
  }

  get hasDataAvailable() {
    return this.inspectionFailureReasonsGallery.length > 0;
  }

  getGallery() {
    this.isLoading = true;
    this.galleryService
      .getInspectionGalleryGroupedByFailureReason(this.inspectionResultDetails.inspectionId)
      .subscribe({
        next: (data) => {
          this.inspectionFailureReasonsGallery = data;
          this.isLoading = false;
        },
        error: () => (this.isLoading = false),
      });
  }

  downloadAsPdf() {
    this.eventBusService.emit(new EmitEvent(EventBusActions.downloadPdf, true));
    const exportGalleryOverview: ExportGalleryOverviewDto = {
      failureReasonsList: this.inspectionFailureReasonsGallery.map((item) => ({
        inspectionGroupIdentifier: Number(item.inspectionItemDetailsGallery[0].inspectionGroupIdentifier),
        inspectionItems: item.inspectionItemDetailsGallery.map((details) => ({
          ...details,
          inspectionItemFailureReasons: details.inspectionItemFailureReasonIds.map((failureReasonId) => ({
            failureReasonId: item.failureReasonId,
            inspectionItemFailureReasonIds: [failureReasonId],
          })),
        })),
      })),
      inspectionResultDetails: this.inspectionResultDetails,
    };

    setTimeout(() => {
      this.exportPdfService.galleryOverviewGeneratePdf(exportGalleryOverview);
    }, 500);
  }
}
