import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageLoadingService {
  private loadingCount = 0;
  private loadingSubject = new BehaviorSubject<boolean>(true);

  loading$ = this.loadingSubject.asObservable();

  incrementLoading() {
    this.loadingCount++;
    this.loadingSubject.next(true);
  }

  decrementLoading() {
    this.loadingCount--;
    if (this.loadingCount === 0) {
      this.loadingSubject.next(false);
    }
  }
}