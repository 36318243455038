<form [formGroup]="createTestForm" (ngSubmit)="saveDetails()" class="create-test-inspection">
  <h1 mat-dialog-title>{{ "CreateTestInspection.Title" | i18next }}</h1>
  <loading *ngIf="isLoading" [spinnerSize]="64"></loading>
  <div mat-dialog-content class="form-container">
    <label class="required">{{ "CreateTestInspection.Labels.NumberOfResults" | i18next }}</label>
    <mat-form-field
      appearance="fill"
      class="width-100"
      [ngClass]="{ 'mb-3': createTestForm.controls['numberOfResults'].errors }"
      hideRequiredMarker
    >
      <mat-label>{{ "CreateTestInspection.Placeholders.NumberOfResults" | i18next }}</mat-label>
      <mat-select formControlName="numberOfResults">
        <mat-option *ngFor="let value of [125, 250]" value="{{ value }}">{{ value }}</mat-option>
      </mat-select>
      <mat-error *ngIf="createTestForm.controls['numberOfResults'].errors"
        >{{ "CreateTestInspection.Labels.NumberOfResults" | i18next }}
        {{ "General.InputRequired" | i18next }}</mat-error
      >
    </mat-form-field>
    <label class="required">{{ "CreateTestInspection.Labels.TypeOfCheck" | i18next }}</label>
    <mat-form-field
      appearance="fill"
      class="width-100"
      [ngClass]="{ 'mb-3': createTestForm.controls['inspectionTypeId'].errors }"
      hideRequiredMarker
    >
      <mat-label>{{ "CreateTestInspection.Placeholders.TypeOfCheck" | i18next }}</mat-label>
      <mat-select formControlName="inspectionTypeId">
        <mat-option *ngFor="let type of inspectionTypes" value="{{ type }}">{{
          "Dashboard.Inspection.Types." + type.toString() | i18next
        }}</mat-option>
      </mat-select>
      <mat-error *ngIf="createTestForm.controls['inspectionTypeId'].errors"
        >{{ "CreateTestInspection.Labels.TypeOfCheck" | i18next }} {{ "General.InputRequired" | i18next }}</mat-error
      >
    </mat-form-field>

    <label class="required">{{ "CreateTestInspection.Labels.SelectDate" | i18next }}</label>
    <mat-form-field
      appearance="fill"
      class="width-100"
      [ngClass]="{ 'mb-3': createTestForm.controls['dateOfInspection'].errors }"
      hideRequiredMarker
    >
      <mat-label>{{ "CreateTestInspection.Labels.SelectDate" | i18next }}</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="dateOfInspection" />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error *ngIf="createTestForm.controls['dateOfInspection'].errors"
        >{{ "CreateTestInspection.Labels.SelectDate" | i18next }} {{ "General.InputRequired" | i18next }}</mat-error
      >
    </mat-form-field>

    <label class="required">{{ "CreateTestInspection.Labels.Auditor" | i18next }}</label>
    <mat-form-field
      class="width-100"
      appearance="fill"
      [ngClass]="{ 'mb-3': createTestForm.controls['auditor'].errors }"
      hideRequiredMarker
    >
      <mat-label>{{ "CreateTestInspection.Labels.Auditor" | i18next }}</mat-label>
      <input type="text" matInput [matAutocomplete]="auto" formControlName="auditor" />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
          {{ user.name }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="createTestForm.controls['auditor'].hasError('required')"
        >{{ "CreateTestInspection.Labels.Auditor" | i18next }} {{ "General.InputRequired" | i18next }}</mat-error
      >
      <mat-error
        *ngIf="!createTestForm.controls['auditor'].hasError('required') && createTestForm.controls['auditor'].errors?.['invalidAuditor']"
      >
        {{ "CreateTestInspection.InvalidAuditor" | i18next }}
      </mat-error>
    </mat-form-field>

    <label>{{ "CreateTestInspection.Labels.Comment" | i18next }}</label>
    <mat-form-field appearance="fill" class="width-100">
      <mat-label>{{ "CreateTestInspection.Placeholders.Comment" | i18next }}</mat-label>
      <textarea matInput formControlName="comment"></textarea>
    </mat-form-field>
  </div>

  <div mat-dialog-actions class="form-actions">
    <doka-button color="clear" (click)="onClose()" class="main-button">
      {{ "General.Cancel" | i18next }}</doka-button
    >
    <button mat-raised-button color="accent" type="submit">{{ "General.Submit" | i18next }}</button>
  </div>
</form>
